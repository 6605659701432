;(function($)
{
    'use strict';
    $(document).ready(function()
    {
        alertDelete();
    });

})(window.jQuery);

function alertDelete () {
    $('table.table').on('click', '.modal-delete', function(e){
        e.preventDefault();
        var linkUrl = $(this).attr('href');
        var titleAlert = $(this).attr('data-title');
        if (!titleAlert) {
            titleAlert = 'Excluir registro';
        };
        var descAlert = $(this).attr('data-desc');
        if (!descAlert) {
            descAlert = 'Deseja realmente excluir esse registro definitivamente?';
        };
        swal({
            title: titleAlert,
            text: descAlert,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim, desejo excluir!",
            closeOnConfirm: false,
            cancelButtonText: "Cancelar",
        }, function() {
            window.location=linkUrl;
        });
    });

}